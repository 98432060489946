<template>
  <section id="welcome" style="padding: 0">

    <v-container fluid>
      <v-layout row wrap style="margin-top: -6%" justify-center align-center>
        <v-flex
          xs12
          sm6
          :style="mdUp ? 'text-align: left;' : 'text-align: center;'"
        >
          <span
            :style="
              mdUp
                ? 'color:#772336;letter-spacing: 3.2px;margin-left:9%;'
                : 'color:#772336;letter-spacing: 3.2px;'
            "
          >
            02
            <div class="line"></div>
            MISIÓN</span
          >
          <br /><br /><br />
          <h1
            :style="
              mdUp
                ? 'font-size:40px;margin-left:6%;margin-right:4%;'
                : 'font-size:30px;'
            "
          >
            Ser una empresa reconocida a nivel nacional.
          </h1>
          <br /><br />
          <h2
            :style="
              mdUp
                ? 'font-size:16px;margin-left:6%;margin-right:4%;'
                : 'font-size:20px;margin-left:2%;margin-right:2%'
            "
          >
            Nuestra misión es ser una empresa reconocida a nivel nacional, con
            equipo y personal altamente capacitado. Ofrecer la más alta garantía
            de nuestro servicio, seguridad, calidad y rapidez nos hace aun mas
            confiables, logrando la excelencia mediante el cuidado del medio
            ambiente.
          </h2>
        </v-flex>
        <v-flex xs12 sm6>
          <v-img contain src="/static/DSC_0017.png"></v-img>
        </v-flex>
        <v-flex xs12 sm6 v-if="mdUp">
          <v-img contain src="/static/DSC_0032.png"></v-img>
        </v-flex>
        <v-flex
          xs12
          sm6
          :style="mdUp ? 'text-align: right;' : 'text-align: center;'"
        >
          <br /><br />
          <span
            :style="
              mdUp
                ? 'color:#772336;letter-spacing: 3.2px;margin-right:9%;'
                : 'color:#772336;letter-spacing: 3.2px;'
            "
          >
            02
            <div class="line"></div>
            VISIÓN</span
          >
          <br /><br />
          <h1
            :style="
              mdUp
                ? 'font-size:40px;margin-right:6%;margin-left:4%;'
                : 'font-size:30px;margin-left:2%;margin-right:2%'
            "
          >
            Asumimos el reto de ser la mejor empresa en el manejo integral de
            residuos.
          </h1>
          <br /><br />
          <h2
            :style="
              mdUp
                ? 'font-size:16px;margin-right:8%;margin-left:4%;'
                : 'font-size:20px;margin-left:2%;margin-right:2%'
            "
          >
            Nuestra visión es asumir el reto de ser la mejor empresa en el
            manejo integral de residuos, superando las expectativas y exigencias
            de nuestros clientes en servicios como; transporte, tratamiento,
            reciclado, y disposición final , labor que nos ha colocado a nivel
            nacional e internacional en una empresa altamente competitiva.
          </h2>
        </v-flex>
        <v-flex xs12 sm6 v-if="!mdUp">
          <v-img contain src="/static/DSC_0032.png"></v-img>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Servicios Title> -->
    <v-container>
      <v-layout row wrap align-center justify-center>
        <h1 :style="mdUp ? 'font-size: 70px' : 'font-size:30px'">Servicios</h1>
      </v-layout>
    </v-container>
    <v-container class="pa-4 text-center">
      <v-row class="fill-height" align="center" justify="center">
        <template v-for="(item, i) in items">
          <v-col :key="i" cols="12" xs="6" md="4">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-img :src="item.img" height="400px">
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="transition-fast-out-fast-in v-card--reveal"
                      :style="
                        item.pos == 0
                          ? 'height: 100%;color: white;text-align: center;font-size: 25px !important;background-color: #772336d1;padding: 75px 20px 0 20px;'
                          : 'height: 100%;color: white;text-align: center;font-size: 25px !important;background-color: #772336d1;padding: 125px 20px 0 20px;'
                      "
                    >
                      <span v-if="item.pos == 0" :style="mdUp ? 'top: 30%;position:absolute;left:10%;right:10%;' : 'top: 45%;position:absolute;left:10%;right:10%;' ">
                        {{ item.title }}
                      </span>
                      <span v-else :style="mdUp ? 'top: 45%;position:absolute;left:10%;right:10%;' : 'top: 45%;position:absolute;left:10%;right:10%;' ">
                        {{ item.title }}
                      </span>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- Algunos de nuestros clientes -->
    <br />
    <v-container>
      <v-layout column wrap align-center justify-center>
        <v-flex
          xs12
          :style="mdUp ? 'text-align: center;' : 'text-align: center;'"
        >
          <h1 :style="mdUp ? 'font-size: 70px' : 'font-size:30px'">
            Algunos de nuestros clientes
          </h1>
        </v-flex>
        <v-flex
          xs12
          :style="mdUp ? 'text-align: center;' : 'text-align: center;'"
        >
          <v-img src="/static/Clientes.png" width="100%"> </v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import page_texts from "../../views/guests/guestTexts";

export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    imagenes: [
      "/static/facilities2.png",
      "/static/facilities2.png",
      "/static/facilities2.png",
    ],
    myservice: null,
    currentTab: 0,
    servicesManu: [
      { name: "METAL FORMING", img: "/static/genvaimg.png" },
      { name: "POWDER COATING", img: "/static/genvaimg.png" },
      { name: "ELECTROPLATING", img: "/static/genvaimg.png" },
      { name: "ZINC DIE CASTING", img: "/static/genvaimg.png" },
      { name: "ASSEMBLY & TESTING", img: "/static/genvaimg.png" },
      { name: "SOURCED AND LANDED ACTIVITIES", img: "/static/genvaimg.png" },
    ],
    servicesComer: [
      { name: "REPRESENTACIÓN COMERCIAL", img: "/static/genvaimg.png" },
      { name: "BRAND AWARENESS / MARKETING", img: "/static/genvaimg.png" },
      { name: "3PL", img: "/static/genvaimg.png" },
      { name: "BRANDS & PRODUCTS", img: "/static/genvaimg.png" },
    ],
    nodesArr: [
      {
        name: "METAL FORMING",
        images: [],
      },
      {
        name: "POWDER COATING",
        images: [],
      },
      {
        name: "ELECTROPLATING",
        images: [],
      },
      {
        name: "ZINC DIE CASTING",
        images: [],
      },
      {
        name: "ASSEMBLY & TESTING",
        images: [],
      },
      {
        name: "SOURCING AND LANDED ACTIVITIES",
        images: [],
      },
    ],
    MainImage: "",
    ExampleImages: [],
    items: [
      {
        title:
          "Confinamiento de Residuos de Manejo Especial de la Industria en General y del Sector Hidrocarburos",
        img: "/static/DSC_LODO.png",
        pos: 0,
      },
      {
        title: "Transporte de Residuos Peligrosos",
        img: "/static/IMG2.png",
        pos: 1,
      },
      {
        title: "Transporte en pipas de presión y vacío",
        img: "/static/IMG3.png",
        pos: 2,
      },
      {
        title: "Transporte de Residuos de Manejo Especial",
        img: "/static/IMG4.png",
        pos: 3,
      },
      {
        title: "Renta de Cargador frontal",
        img: "/static/IMG5.png",
        pos: 4,
      },
      {
        title: "Renta de Presas para almacenamiento de Lodo",
        img: "/static/IMG6.png",
        pos: 5,
      },
    ],
    transparent: "rgba(255, 255, 255, 0)",
  }),
  computed: {
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
    texts() {
      // return the texts JSON based on the language chosen
      // if more languages are added, convert into a switch with param (this.language)
      return this.language == 0 ? page_texts.spanish : page_texts.english;
    },
  },
  watch: {
    currentTab(val) {},
  },
  components: {
    ImageCarrousel: () => import("../core/ImageCarrousel"),
  },
  methods: {

  },
  mounted() {
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

div.line {
  margin-bottom: 0.5%;
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  width: 10%;
  border-bottom: 1px solid #772336;
}

.v-slide-group__prev {
  display: none;
}
.mdFlexMargin {
  margin-bottom: 20px;
}
.flex.md5-custom {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
.header_desktop1 {
  text-align: center;
  font-size: 45px;
}

.header_mobile {
  font-size: 20px;
  text-align: center;
}
.doctor-info-div {
  background-color: #e4edf1;
  padding: 100px;
}
.doctor-info-div-mobile {
  background-color: #e4edf1;
  padding: 20px;
}
.align-center-class {
  text-align: center;
}
.gradient-wrapper {
  position: relative;
}
.gradient-wrapper:after {
  content: "";
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    to bottom,
    rgba(56, 65, 117, 0.7) 0%,
    rgba(56, 65, 117, 0.7) 100%
  );
}
.blue-div {
  position: relative;
  margin-top: 20px;
  font-size: 22px;
  height: 4px;
  width: 100px;
  background-color: #81ccf7;
  margin-bottom: 20px;
}
.medium-text {
  font-size: 22px;
  font-weight: bold;
}
.small-text {
  font-size: 12px;
}
.welcome-flex-sm {
  position: relative;
  padding: 0 5px !important;
}
.welcome-container-md {
  padding: 0 10vw;
  margin: 0;
  width: 100%;
}
.text-flex-sm {
  text-align: center !important;
}
.container_text_home_card {
  margin-top: 180px;
  margin-bottom: 180px;
}
.adjust_home_card {
  margin-left: 8%;
  margin-top: -14%;
}
.adjust_home_car_mobile {
}
.opening_quote {
  width: 150px;
}
.opening_quote_mobile {
  width: 50px;
  margin-left: 10px;
}
.v-tabs-slider {
  display: none !important;
}
.active-tab {
  background-color: #d8dcef !important;
}
.closing_quote {
  margin-top: 50px;
  width: 150px;
  margin-bottom: -70px;
  margin-left: 10px;
}
.closing_quote_mobile {
  position: relative;
  bottom: 30px;
  width: 50px;
  margin-right: 10px;
}
.home_card_text {
  width: 40vw;
  position: absolute;
  margin-top: 10%;
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: lighter;
  font-style: normal;
  text-align: left;
  margin: auto;
  max-width: 80%;
}

.home_card_text_mobile {
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 4vw;
  font-weight: lighter;
  font-style: italic;
  text-align: center;
  margin: auto;
  max-width: 80%;
}
.doctor-img {
  position: relative;
  right: 30%;
  max-width: 30vw;
  max-height: 45vw;
}
.doctor-img-mobile {
  position: relative;
  margin-left: 30%;
  max-width: 30vw;
  max-height: 50vw;
}
</style>
