var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"padding":"0"},attrs:{"id":"welcome"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"margin-top":"-6%"},attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{style:(_vm.mdUp ? 'text-align: left;' : 'text-align: center;'),attrs:{"xs12":"","sm6":""}},[_c('span',{style:(_vm.mdUp
              ? 'color:#772336;letter-spacing: 3.2px;margin-left:9%;'
              : 'color:#772336;letter-spacing: 3.2px;')},[_vm._v(" 02 "),_c('div',{staticClass:"line"}),_vm._v(" MISIÓN")]),_c('br'),_c('br'),_c('br'),_c('h1',{style:(_vm.mdUp
              ? 'font-size:40px;margin-left:6%;margin-right:4%;'
              : 'font-size:30px;')},[_vm._v(" Ser una empresa reconocida a nivel nacional. ")]),_c('br'),_c('br'),_c('h2',{style:(_vm.mdUp
              ? 'font-size:16px;margin-left:6%;margin-right:4%;'
              : 'font-size:20px;margin-left:2%;margin-right:2%')},[_vm._v(" Nuestra misión es ser una empresa reconocida a nivel nacional, con equipo y personal altamente capacitado. Ofrecer la más alta garantía de nuestro servicio, seguridad, calidad y rapidez nos hace aun mas confiables, logrando la excelencia mediante el cuidado del medio ambiente. ")])]),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-img',{attrs:{"contain":"","src":"/static/DSC_0017.png"}})],1),(_vm.mdUp)?_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-img',{attrs:{"contain":"","src":"/static/DSC_0032.png"}})],1):_vm._e(),_c('v-flex',{style:(_vm.mdUp ? 'text-align: right;' : 'text-align: center;'),attrs:{"xs12":"","sm6":""}},[_c('br'),_c('br'),_c('span',{style:(_vm.mdUp
              ? 'color:#772336;letter-spacing: 3.2px;margin-right:9%;'
              : 'color:#772336;letter-spacing: 3.2px;')},[_vm._v(" 02 "),_c('div',{staticClass:"line"}),_vm._v(" VISIÓN")]),_c('br'),_c('br'),_c('h1',{style:(_vm.mdUp
              ? 'font-size:40px;margin-right:6%;margin-left:4%;'
              : 'font-size:30px;margin-left:2%;margin-right:2%')},[_vm._v(" Asumimos el reto de ser la mejor empresa en el manejo integral de residuos. ")]),_c('br'),_c('br'),_c('h2',{style:(_vm.mdUp
              ? 'font-size:16px;margin-right:8%;margin-left:4%;'
              : 'font-size:20px;margin-left:2%;margin-right:2%')},[_vm._v(" Nuestra visión es asumir el reto de ser la mejor empresa en el manejo integral de residuos, superando las expectativas y exigencias de nuestros clientes en servicios como; transporte, tratamiento, reciclado, y disposición final , labor que nos ha colocado a nivel nacional e internacional en una empresa altamente competitiva. ")])]),(!_vm.mdUp)?_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-img',{attrs:{"contain":"","src":"/static/DSC_0032.png"}})],1):_vm._e()],1)],1),_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('h1',{style:(_vm.mdUp ? 'font-size: 70px' : 'font-size:30px')},[_vm._v("Servicios")])])],1),_c('v-container',{staticClass:"pa-4 text-center"},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_vm._l((_vm.items),function(item,i){return [_c('v-col',{key:i,attrs:{"cols":"12","xs":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-img',{attrs:{"src":item.img,"height":"400px"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"transition-fast-out-fast-in v-card--reveal",style:(item.pos == 0
                        ? 'height: 100%;color: white;text-align: center;font-size: 25px !important;background-color: #772336d1;padding: 75px 20px 0 20px;'
                        : 'height: 100%;color: white;text-align: center;font-size: 25px !important;background-color: #772336d1;padding: 125px 20px 0 20px;')},[(item.pos == 0)?_c('span',{style:(_vm.mdUp ? 'top: 30%;position:absolute;left:10%;right:10%;' : 'top: 45%;position:absolute;left:10%;right:10%;')},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',{style:(_vm.mdUp ? 'top: 45%;position:absolute;left:10%;right:10%;' : 'top: 45%;position:absolute;left:10%;right:10%;')},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e()])],1)],1)]}}],null,true)})],1)]})],2)],1),_c('br'),_c('v-container',[_c('v-layout',{attrs:{"column":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{style:(_vm.mdUp ? 'text-align: center;' : 'text-align: center;'),attrs:{"xs12":""}},[_c('h1',{style:(_vm.mdUp ? 'font-size: 70px' : 'font-size:30px')},[_vm._v(" Algunos de nuestros clientes ")])]),_c('v-flex',{style:(_vm.mdUp ? 'text-align: center;' : 'text-align: center;'),attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":"/static/Clientes.png","width":"100%"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }